import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { formats, convert } from '@/helpers/data'
import dayjs from 'dayjs'

class Receipt extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  // Required for $FeathersVuex plugin to work after production transpile.
  static modelName = 'Receipt'

  static instanceDefaults() {
    return {
      amount: 0,
      stateId: 1,
      depositType: 'Check',
      receiptDate: dayjs(new Date()).startOf('day').toISOString(),
    }
  }

  get fullName() {
    return `${ this.firstName } ${ this.lastName }`;
  }

  get formattedPhone() {
    return formats.phone(this.phone)
  }

  get fullAddress() {
    if (!this.state) return this.address.street;

    return `${ this.address.street }, ${ this.address.city },
      ${ this.state.short } ${ this.address.zipCode }`
  }

  get formattedMoney() {
    return convert(this.amount, 'toMoney')
  }
}
const servicePath = 'receipts'
const servicePlugin = makeServicePlugin({
  Model: Receipt,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin