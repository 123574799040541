import Vue from 'vue'

Vue.filter('toCurrency', (value, dec = 0) => {
  if (typeof value !== "number")
    return value;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: dec
  });
  return formatter.format(value / 100);
});