export default () => {
  return [
    {
      path: '/notifications',
      name: 'notifications',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/notifications/Notification.List')
    }
  ]
}