export default function() {
  class ActionBuilder {
    constructor (params) {
      this.params = params;
      this.actions = {
        edit: false,
        delete: false,
        submit: false,
        adminRejected: false,
        reject: false,
        approve: false,
        confirm: false
      };
    }

    getContext() {
      const {
        id: userId,
        organization: {
          expensesApprovedBy,
          expensesConfirmedBy
        },
        roleId
      } = this.params.user;
      const isAdmin = roleId === 5;
      const isViewer = roleId === 7;
      const canApprove = expensesApprovedBy.includes(userId);
      const canConfirm = expensesConfirmedBy.includes(userId);

      return {
        roleId,
        isAdmin,
        isViewer,
        canApprove,
        canConfirm
      }
    }

    set(keys) {
      for (const key of keys)
        this.actions[key] = true;
    }

    get() {
      return this.actions;
    }
  }

  const buildActions = (status, context) => {
    const builder = new ActionBuilder();
    const {
      canApprove,
      canConfirm
    } = context;

    switch (status) {
      case 'incomplete':
        builder.set(['edit', 'delete', 'submit']);
        break;
      case 'submitted':
        if (canApprove)
          builder.set(['revert', 'reject', 'approve', 'delete']);
        break;
      case 'rejected':
        if (canApprove)
          builder.set(['edit', 'submit', 'approve']);
        break;
      case 'approved':
        if (canConfirm)
          builder.set(['revert', 'reject', 'confirm']);
        break;
    }

    builder.actions.hasActions = Object
      .values(builder.actions)
      .some(a => a)

    return builder.get();
  };

  const buildGetActions = context => {
    const { result: expense, params } = context;

    if (params.user) {
      const builder = new ActionBuilder(params);
      const actionContext = builder.getContext()
      const { status: currentStatus } = expense;

      expense.actions = buildActions(
        currentStatus,
        actionContext
      )
    }
  };

  const buildFindActions = context => {
    const {
      result: { data: expenses },
      params
    } = context;
    const builder = new ActionBuilder(params);
    const actionContext = builder.getContext()

    for (const expense of expenses) {
      const { status: currentStatus } = expense;
      const actions = buildActions(currentStatus, actionContext);

      expense.actions = actions;
    }
  };

  return {
    buildActions,
    buildGetActions,
    buildFindActions
  };
}