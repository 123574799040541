import store from '@/store'
import helper from '@/router/helper';

export default router => {
  const { canCheck } = helper(router)
  
  return [
    {
      path: '/login',
      name: 'login',
      meta: {
        requiresAuth: false
      },
      component: () => import('@/views/users/Login')
    },
    {
      path: '/reset-password',
      name: 'reset-password',
      meta: {
        requiresAuth: false,
        props: true
      },
      component: () => import('@/views/users/ResetPassword')
    },
    {
      path: '/set-password',
      name: 'set-password',
      meta: {
        requiresAuth: false,
        props: true
      },
      component: () => import('@/views/users/SetPassword')
    },
    {
      path: '/users',
      name: 'users',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('read', 'User', 'orgGroup')
      },
      component: () => import('@/views/users/User.List')
    },
    {
      path: '/admins',
      name: 'admins',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('read', 'admin')
      },
      component: () => import('@/views/users/Admins.List')
    },
    {
      path: '/organizationAdmins',
      name: 'organizationAdmins',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      beforeEnter: (to, from, next) => {
        const { roleId } = store.getters['auth/user']
        if ([1,2].includes(roleId)) next()
        else next('/dashboard')
      },
      component: () => import('@/views/users/OrgAdmin.List')
    },
    {
      path: '/committeeAdmins',
      name: 'committeeAdmins',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/users/CommitteeAdmins.List')
    },
    {
      path: '/organizationAdmin/:id',
      name: 'organizationAdmin',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/users/OrganizationAdmin')
    },
    {
      path: '/account',
      name: 'account',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/users/Account')
    }
  ]
}