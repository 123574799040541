import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class UserPreference extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'UserPreference'
}

const servicePath = 'user-preferences'
const servicePlugin = makeServicePlugin({
  Model: UserPreference,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({
  before: {},
  after: {},
  error: {}
})

export default servicePlugin