export default router => {
  return [
    {
      path: '/organization-types',
      name: 'organization-types',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/organization-types/OrgType.List')
    }
  ]
}