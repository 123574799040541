import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class IRSDesignation extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'IRSDesignation'
  static instanceDefaults() {
    return {}
  }
}
const servicePath = 'irs-designations'
const servicePlugin = makeServicePlugin({
  Model: IRSDesignation,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin