import { Ability, createAliasResolver, detectSubjectType as defaultDetector } from '@casl/ability';
import { BaseModel } from '@/api-client';

const detectSubjectType = (subject) => {
  if (typeof subject === 'string') return subject;
  if (!(subject instanceof BaseModel)) return defaultDetector(subject);
  return subject.constructor.servicePath;
}

const resolveAction = createAliasResolver({
  update: 'patch',
  read: ['get', 'find'],
  delete: 'remove'       
});

const ability = new Ability([], { detectSubjectType, resolveAction });

const caslPlugin = (store) => {
  store.registerModule('casl', {
    namespaced: true,
    state: {
      ability,
      rules: []
    },
    mutations: {
      setRules(state, rules) {
        for (let rule of rules) {
          if (rule.conditions) {
            const conditionKeys = Object.keys(rule.conditions);
            for (let key of conditionKeys) {
              if (key.indexOf('.') !== -1) {
                const [namespace, id] = key.split('.');
                rule.conditions[id] = rule.conditions[key];
                delete rule.conditions[key];
              }
            }
          }
        }
        state.rules = rules;
        state.ability.update(rules);
      }
    }
  });
  store.subscribeAction({
    after: (action, state) => {
      if (action.type === 'auth/responseHandler') {
        const { rules } = action.payload;
        if (!rules || !state.auth.user) {
          store.commit('casl/setRules', []);
          return;
        }

        store.commit('casl/setRules', rules);

      } else if (action.type === 'auth/logout') {
        store.commit('casl/setRules', []);
      }
    }
  });
};

export {
  ability,
  caslPlugin
};