import { diff } from 'deep-object-diff'
import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class User extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'User'
  static instanceDefaults() {
    return {
      email: '',
      password: ''
    }
  }
  static diffOnPatch(data) {
    const orgUser = User.store.state.users.keyedById[data.id]
    return diff(orgUser, data)
  }

  get fullName () {
    return this.firstName + ' ' + this.lastName
  }

  get statusColor () {
    switch (this.status) {
      case 'new': return 'blue'
      case 'pending': return 'orange darken-2'
      case 'inactive': return 'red'
      case 'active': return 'green'
      default: return 'blue'
    }
  }

  get textColor () {
    switch (this.status) {
      case 'new': return 'blue lighten-5'
      case 'pending': return 'white'
      case 'inactive': return 'red lighten-5'
      case 'active': return 'green lighten-5'
      default: return 'blue lighten-5'
    }
  }
}
const servicePath = 'users'
const servicePlugin = makeServicePlugin({
  Model: User,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin