import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class Attachment extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Attachment'
}

const servicePath = 'attachments'
const servicePlugin = makeServicePlugin({
  Model: Attachment,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({})
export default servicePlugin