export default (roleId) => {
  switch (roleId) {
    case 3: // CommitteeAdmin
      return import('@/views/dashboard/CommitteeAdminDashboard')
    case 5: // OrganizationAdmin
      return import('@/views/dashboard/OrganizationAdminDashboard')
    case 6: // OrganizationUser
    case 7:
    case 8:
      return import('@/views/dashboard/OrganizationUserDashboard');
    default:
      return import('@/views/dashboard/Dashboard')
  }
}