import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class AdvocacyOrganizationType extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'AdvocacyOrganizationType'

  static setupInstance (data) { return data }
}
const servicePath = 'advocacy-organization-types'
const servicePlugin = makeServicePlugin({
  Model: AdvocacyOrganizationType,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({})

export default servicePlugin