import Vue from 'vue'
import App from './App.vue'
import store from './store'
import routeData from './router'
import VueCompositionApi from '@vue/composition-api'
import vuetify from './plugins/vuetify'
import JsonExcel from "vue-json-excel";
import './filters/string.js'
import './filters/currency.js'
import './plugins/dayjs'
import './plugins/vuetify-dialog'
import "./styles/tailwind/tailwind.css"
import "./styles/global.scss"
import { abilitiesPlugin } from '@casl/vue';
import ECharts from 'vue-echarts'
import { use } from 'echarts/core'

import { createPinia } from 'pinia'
const pinia = createPinia()

Vue.use(pinia)
Vue.use(VueCompositionApi)
Vue.use(abilitiesPlugin, store.state.casl.ability);
Vue.component("downloadExcel", JsonExcel);

import VuetifyDialog from 'vuetify-dialog'

Vue.use(VuetifyDialog, {
  context: { vuetify }
})

// eCharts
import { PieChart } from 'echarts/charts';
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent
} from "echarts/components";
use([
  PieChart,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent
])
Vue.component('v-chart', ECharts)

// add routes after the casl to access $can within them
routeData.routes.forEach(route => routeData.router.addRoute(route));

Vue.config.productionTip = false

Vue.mixin({
  methods: {
    $snackSuccess (message) {
      this.$store.commit('setSuccessSnack', message)
    },
    $snackError (message) {
      this.$store.commit('setErrorSnack', message)
    },
  }
})

store.dispatch('auth/authenticate')
  .catch((error) => {
    console.error(error)
    window.localStorage.removeItem('feathers-jwt')
  })
  .then(() => {
    new Vue({
      store,
      router: routeData.router,
      vuetify,
      render: h => h(App)
    }).$mount('#app')
  })