import feathers from '@feathersjs/feathers'
import socketio from '@feathersjs/socketio-client'
import auth from '@feathersjs/authentication-client'
import io from 'socket.io-client'
import { iff, discard } from 'feathers-hooks-common'
import feathersVuex from 'feathers-vuex'

const socket = io(process.env.VUE_APP_API_URL, { transports: ['websocket'] })

const feathersClient = feathers()
  .configure(socketio(socket))
  .configure(auth({ storage: window.localStorage }))
  .hooks({
    before: {
      all: [
        iff(
          context => ['create', 'update', 'patch'].includes(context.method),
          discard('__id', '__isTemp')
        )
      ]
    }
  })

export default feathersClient

// Setting up feathers-vuex
const { makeServicePlugin, makeAuthPlugin, BaseModel, models, FeathersVuex } = feathersVuex(
  feathersClient,
  {
    serverAlias: 'api',
    idField: 'id',
    paramsForServer: ['$eager', '$joinRelation', '$joinEager', '$assignOrgId', '$sum',
      '$ilike', '$allowRefs', '$joinEager', '$uploads', '$modify', 'disableSoftDelete', '$join'],
    whitelist: ['$regex', '$options', '$eager', '$joinRelation', '$joinEager', '$sum',
      '$assignOrgId', '$ilike', '$allowRefs', '$joinEager', '$uploads', '$modify', '$join']
  }
)

export { makeAuthPlugin, makeServicePlugin, BaseModel, models, FeathersVuex }