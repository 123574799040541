import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class Export extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Export'
}

const servicePath = 'exporter'
const servicePlugin = makeServicePlugin({
  Model: Export,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({})
export default servicePlugin