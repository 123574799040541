import helper from '@/router/helper';

export default router => {
  const { canCheck } = helper(router)

  return [
    {
      path: '/candidates',
      name: 'candidates',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('find', 'Candidate', 'org')
      },
      component: () => {
        const store = router.app.$store;
        const { group } = store.getters['auth/user'];

        return group === 'organization'
          ? import('@/views/candidates/Candidate.List')
          : import('@/views/candidates/Candidate.Committee.Edit')
      }
    },
    {
      path: '/candidates/edit/:id',
      name: 'candidateEdit',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('find', 'Candidate', 'org')
      },
      component: () => import('@/views/candidates/Candidate.Edit')
    }
  ]
}