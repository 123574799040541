import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { pick, keys } from 'lodash'
import { chambers } from '@/helpers/campaignData'

class Campaign extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Campaign'
  static instanceDefaults() {
    return {
      chamber: 'state-house',
      districtNumber: null,
      party: 'republican',
      supportLevel: 'support',
      primaryElectionDate: new Date().toISOString().substring(0, 10),
      generalElectionDate: new Date().toISOString().substring(0, 10),
      numberOfVoters: 10000,
      budget: 0
    }
  }

  getInstanceValues() {
    const instanceKeys = keys(this.constructor.instanceDefaults())
    return pick(this, instanceKeys)
  }

  get formattedChamber() {
    const chamberValues = chambers.find(c => c.value === this.chamber)
    return chamberValues ? chamberValues.text : {};
  }

  get isValid() {
    const instanceObject = this.getInstanceValues()
    return Object.values(instanceObject).every(v => !!v)
  }
}
const servicePath = 'campaigns'
const servicePlugin = makeServicePlugin({
  Model: Campaign,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin