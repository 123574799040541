import feathersClient, { makeServicePlugin, BaseModel } from '@/api-client'

class AuthManagement extends BaseModel {
  static modelName = 'AuthManagement'
}
const servicePath = 'authManagement'
const servicePlugin = makeServicePlugin({
  Model: AuthManagement,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  }
})

export default servicePlugin
