import Vue from 'vue'
import Vuex from 'vuex'
import { FeathersVuex } from '../api-client'
import auth from './store.auth'
import { caslPlugin } from './vuex.plugin.casl';

Vue.use(Vuex)
Vue.use(FeathersVuex)

const requireModule = require.context(
  './services',
  false,
  /\.js$/
)
const servicePlugins = requireModule
  .keys()
  .map(modulePath => requireModule(modulePath).default)

const state = () => ({
  successSnack: '',
  errorSnack: '',
})

const mutations = {
  setSuccessSnack (state, snack) {
    state.successSnack = snack
  },
  setErrorSnack (state, snack) {
    state.errorSnack = snack
  }
}

export default new Vuex.Store({
  state,
  actions: {},
  mutations,
  plugins: [...servicePlugins, auth, caslPlugin]
})