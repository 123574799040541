import { isString } from 'lodash'

const formats = {
  DATE: 'MMM Do, YYYY',
  DATE_SHORT: 'MMM Do',

  phone(val) {
    const strVal = "" + val;
    if (strVal.length > 10) return val;
    return strVal
      .replace(/\D+/g, '')
      .replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
  }
}
const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD'
});
const removeCommaAndDollar = (value) => value.replace(/\$|,/g, '');
const convert = (value, type) => {
  switch(type) {
    case 'toNum': {
      const floated = isString(value)
        ? parseFloat(removeCommaAndDollar(value))
        : value;
      return Math.round(floated * 100);
    }
    case 'toMoney': {
      if(isString(value))
        value = parseFloat(value)

      const amount = parseFloat(value / 100)
      return formatter.format(amount);
    }
    default:
      return value;
  }
}

export {
  formats as formats,
  convert as convert
}