import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { convert } from '@/helpers/data'
import expensesActionBuilder from '@/helpers/expensesActionBuilder'
import dayjs from 'dayjs'

class Expense extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Expense'

  static instanceDefaults() {
    return {
      status: 'incomplete',
      type: 'Direct Mail',
      amount: 0,
      assignedTo: 'Candidate',
      isDirectContribution: true,
      invoiceDate: dayjs(new Date()).startOf('day').toISOString(),
      runDateStart: dayjs(new Date()).startOf('day').toISOString(),
      runDateEnd: dayjs(new Date()).startOf('day').toISOString(),
      notes: ''
    }
  }

  static setupInstance (data, { models }) {
    if (data.candidate) {
      data.candidate = new models.api.Candidate(data.candidate)
    }
    if (data.candidate) {
      data.campaign = new models.api.Campaign(data.campaign)
    }
    return data
  }

  get rejectNote() {
    if (this.history?.length)
      return this.history[0].note;

    return null;
  }

  get formattedMoney() {
    return convert(this.amount, 'toMoney')
  }

  get title() {
    const name = this.name
    const fnln = `${ this.firstName } ${ this.lastName }`
    return name || fnln || 'N/A'
  }
}

const { buildGetActions, buildFindActions } = expensesActionBuilder();

const servicePath = 'expenses'

const servicePlugin = makeServicePlugin({
  Model: Expense,
  service: feathersClient.service(servicePath),
  servicePath,
  handleEvents: {
    created: (data, { model }) => {
      const { store } = model;
      const user = store.getters['auth/user']

      buildGetActions({ result: data, params: { user } });
    },
    patched: (data, { model }) => {
      const { store } = model;
      const user = store.getters['auth/user']

      buildGetActions({ result: data, params: { user } });
    }
  }
})

feathersClient.service(servicePath).hooks({
  after: {
    find: [ buildFindActions ],
    get: [ buildGetActions ],
    patch: [ buildGetActions ]
  }
})

export default servicePlugin