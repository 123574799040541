import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class OrganizationType extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'OrganizationType'
  static instanceDefaults() {
    return {
      irsDesignationId: 1,
      address: { stateId: 1 }
    }
  }

  get fullAddress() {
    if (!this.state) return this.address.street;

    return `${ this.address.street }, ${ this.address.city },
      ${ this.state.short } ${ this.address.zipCode }`
  }
}
const servicePath = 'organization-types'
const servicePlugin = makeServicePlugin({
  Model: OrganizationType,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin