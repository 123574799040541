export default router => {
  return [
    {
      path: '/expenses',
      name: 'expenses',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/expenses/Expense.List')
    },
    {
      path: '/expenses/createEdit/:id',
      name: 'expensesCreateEdit',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/expenses/Expense.CreateEdit')
    },
    {
      path: '/expenses/view/:id',
      name: 'expensesView',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/expenses/Expense.View')
    }
  ]
}