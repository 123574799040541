<template lang="pug">
v-app
  vue-extend-layouts

  v-snackbar(
    :timeout="4000"
    v-model="showSuccessSnack"
    color="success"
    outlined
    right
  )
    .d-flex.align-center
      v-btn(
        small
        icon
        color="green"
        @click.native="showSuccessSnack = false"
      )
        v-icon mdi-checkbox-marked-circle
      p.ma-0.ml-4 {{ message }}

  v-snackbar(
    top
    :timeout="4000"
    v-model="showErrorSnack"
    color="error"
  )
    .d-flex.align-center
      v-btn(
        icon
        color="white"
        @click.native="showErrorSnack = false"
      )
        v-icon mdi-close-circle
      p.ma-0 {{ message }}

</template>

<script>
import VueExtendLayouts from 'vue-extend-layout'

export default {
  name: 'App',
  components: { VueExtendLayouts },

  data: () => ({
    message: '',
    showSuccessSnack: false,
    showErrorSnack: false
  }),

  created () {
    this.$store.watch(state => state.successSnack, () => {
      const msg = this.$store.state.successSnack
      if (msg !== '') {
        this.showSuccessSnack = true
        this.message = this.$store.state.successSnack
        this.$store.commit('setSuccessSnack', '')
      }
    })

    this.$store.watch(state => state.errorSnack, () => {
      const msg = this.$store.state.errorSnack
      if (msg !== '') {
        this.showErrorSnack = true
        this.message = this.$store.state.errorSnack
        this.$store.commit('setErrorSnack', '')
      }
    })
  }
};
</script>
