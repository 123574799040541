import helper from '@/router/helper';

export default router => {
  const { canCheck } = helper(router)

  return [
    {
      path: '/advocacies',
      name: 'advocacy',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('find', 'Advocacy', 'org')
      },
      component: () => import('@/views/advocacies/Advocacy.List')
    },
    {
      path: '/advocacies/view/:id',
      name: 'advocacyView',
      meta: {
        requiresAuth: true,
        layout: 'sidebar',
        can: () => canCheck('find', 'Advocacy', 'org')
      },
      component: () => import('@/views/advocacies/Advocacy.View')
    }
  ]
}