import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import UserRoutes from '../views/users/routes'
import CandidatesRoutes from '../views/candidates/routes'
import AdvocacyRoutes from '../views/advocacies/routes'
import ExpenseRoutes from '../views/expenses/routes'
import ReceiptRoutes from '../views/receipts/routes'
import DashboardRoute from '../views/dashboard/routes'
import OrganizationTypeRoutes from '../views/organization-types/routes'
import Notifications from '../views/notifications/routes'
import Organizations from '../views/organizations/routes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: []
})

const routes = [
  {
    path: '/',
    redirect: '/login'
  },

  ...UserRoutes(router),
  ...OrganizationTypeRoutes(router),
  ...CandidatesRoutes(router),
  ...AdvocacyRoutes(router),
  ...ExpenseRoutes(router),
  ...ReceiptRoutes(router),
  ...Notifications(router),
  ...Organizations(router),

  {
    path: '/dashboard',
    name: 'dashboard',
    meta: {
      layout: 'sidebar',
      requiresAuth: true
    },
    component: () => {
      const { roleId } = store.getters['auth/user'];
      return DashboardRoute(roleId);
    }
  },

  {
    path: '/noOrganization',
    name: 'noOrganization',
    meta: {
      layout: 'sidebar',
      requiresAuth: true,
    },
    component: () => import('@/views/common/NoOrganization')
  },

  {
    path: "/:catchAll(.*)",
    name: 'pageNotFound',
    component: () => import('@/views/common/PageNotFound'),
    meta: {
      requiresAuth: false,
    },
  }
]

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters['auth/isAuthenticated']
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const superAdminOnly = to.matched.some(record => record.meta.superAdminOnly)

  if (to.meta?.can && to.meta.can())
    to.meta.can()

  if (requiresAuth && !isAuthenticated) {
    next({ name: 'login' })
  } else {
    const user = store.getters['auth/user']

    if (user) {
      const { organizationId, roleId } = user

      // lock things down with no organization
      if (isAuthenticated && !organizationId && to.name !== 'noOrganization')
        next({ name: 'noOrganization' })
      // block all attempts at superAdmin only routes unless your SuperAdmin (superUsers)
      else if (superAdminOnly && roleId !== 1)
        next({ name: 'dashboard' })
    }

    // check if the "find" is allowed
    if (!to.meta?.can || to.meta.can())
      next();
    else
      next({ name: 'dashboard' })
  }
})

export default { router, routes }
