export default router => {
  return [
    {
      path: '/org-settings',
      name: 'orgSettings',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/organizations/Org.Settings')
    }
    // {
    //   path: '/organizations/view/:id',
    //   name: 'organizationsView',
    //   meta: {
    //     requiresAuth: true,
    //     layout: 'sidebar'
    //   },
    //   component: () => import('@/views/organizations/Organization.View')
    // }
  ]
}