import { isObject, reduce, isEqual } from 'lodash'

export const diffOnPatch = (oldObj, newObj) => {
  return reduce(newObj, (result, value, key) => {
    // If both values are objects, check if they are different
    if (isObject(value) && isObject(oldObj[key])) {
      // If the objects are different, take the whole object from newObj
      if (!isEqual(value, oldObj[key])) {
        result[key] = value;
      }
    } else if (!isEqual(value, oldObj[key])) {
      // For non-object values, if they are different, take the value from newObj
      result[key] = value;
    }
    return result;
  }, {});
}