import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { convert } from '../../helpers/data'
import { diff } from 'deep-object-diff'

class Candidate extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Candidate'
  static setupInstance (data, { models }) {
    if (data.campaign) {
      data.campaign = new models.api.Campaign(data.campaign)
    }
    if (data.committee) {
      data.committee = new models.api.Organization(data.committee);
    }
    return data
  }

  static diffOnPatch (data) {
    const originalObject = Candidate.store.state.candidates.keyedById[data.id]
    const _address = originalObject.treasurerAddress

    const diffed = diff(originalObject, data)

    if (diffed.treasurerAddress)
      diffed.treasurerAddress = Object.assign({}, _address, diffed.treasurerAddress)

    return diffed
  }

  static instanceDefaults() {
    return {
      stateId: 1,
      treasurerAddress: { stateId: 1 },
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      organizationTypeIds: []
    }
  }

  get fullName() {
    return `${ this.firstName } ${ this.lastName }`
  }

  get fullTreasurerName() {
    return `${ this.treasurerFirstName } ${ this.treasurerLastName }`
  }

  get fullAddress() {
    if (!this.state) return this.street;
    if (!this.street) return this.state.full

    return `${ this.street }, ${ this.city },
      ${ this.state.short } ${ this.zipCode }`
  }

  get fullTreasurerAddress() {
    if (!this.treasurerState) return '';
    if (!this.treasurerAddress.street) return this.treasurerState.full

    return `${ this.treasurerAddress.street } ${ this.treasurerAddress.city },
      ${ this.treasurerState.short } ${ this.treasurerAddress.zipCode }`
  }

  get fullCommitteeAddress() {
    if (!this.committee?.short) return '';
    if (!this.committee.address.street) return this.committee.short

    return `${ this.committee.address.street } ${ this.committee.address.city },
      ${ this.committee.short } ${ this.committee.address.zipCode }`
  }

  get formattedMoney() {
    return convert(this.amount, 'toMoney')
  }
}
const servicePath = 'candidates'
const servicePlugin = makeServicePlugin({
  Model: Candidate,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin