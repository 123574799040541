import helper from '@/router/helper';

export default router => {
  const { canCheck } = helper(router)

  return [
    {
      path: '/receipts',
      name: 'receipts',
      meta: {
        requiresAuth: true,
        layout: 'sidebar'
      },
      component: () => import('@/views/receipts/Receipt.List')
    }
  ]
}