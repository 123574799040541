import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1E3A8A',
        secondary: '#B91C1C',
        accent: '#fff',

        error: '#DC2626',
        warning: '#F59E0B',
        success: '#059669'
      }
    }
  }
});
