import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { convert } from '../../helpers/data'
import { diff } from 'deep-object-diff'
import dayjs from 'dayjs'

class Advocacy extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Advocacy'
  static setupInstance (data) { return data }

  static diffOnPatch (data) {
    const originalObject = Advocacy.store.state.advocacies.keyedById[data.id]
    return diff(originalObject, data)
  }

  static instanceDefaults() {
    return {
      name: '',
      billNumber: '',
      budget: 0,
      organizationTypeIds: [],
      startDate: new Date().toISOString(),
      endDate: new Date().toISOString()
    }
  }

  get dateRange() {
    const FMT = 'MMM Do'
    const { startDate, endDate } = this
    return `${ dayjs(startDate).format(FMT) } - ${ dayjs(endDate).format(FMT) }`
  }

  get formattedMoney() {
    return convert(this.amount, 'toMoney')
  }

  get formattedBudget() {
    return convert(this.budget, 'toMoney')
  }
}
const servicePath = 'advocacies'
const servicePlugin = makeServicePlugin({
  Model: Advocacy,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {},
  after: {},
  error: {}
})

export default servicePlugin