import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class CandidateOrganizationType extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'CandidateOrganizationType'

  static setupInstance (data, { models }) {
    if (data.candidate) {
      data.candidate = new models.api.Candidate(data.candidate)
    }
    return data
  }
}
const servicePath = 'candidate-organization-types'
const servicePlugin = makeServicePlugin({
  Model: CandidateOrganizationType,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({})

export default servicePlugin