import store from '@/store'

export default router => {
  const canCheck = (ability, item, checkType = 'group') => {
    const hasCan = router.app && router.app.$can;
    const user = store.getters['auth/user'];
    if (!user) return false;

    if (checkType === 'org') {
      const { organizationId } = user
      const Model = router.app.$root.$FeathersVuex.api[item];

      /*  how would this happen? not sure, just check */
      if (!Model) return false

      return hasCan
        ? router.app.$can(ability, new Model({ organizationId }))
        : true

    } else if (checkType === 'orgGroup') {
      const { organizationId, group } = user
      const Model = router.app.$root.$FeathersVuex.api[item];

      return hasCan
        ? router.app.$can(ability, new Model({ organizationId, group }))
        : true

    } else {
      const group = item;
      const { User } = router.app.$root.$FeathersVuex.api;

      return hasCan
        ? router.app.$can(ability, new User({ group }))
        : true
    }
  }

  return { canCheck }
}