import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'
import { diffOnPatch } from '@/composables/diff-on-patch'

class Organization extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Organization'

  static diffOnPatch (data) {
    const originalObject = Organization.store.state.organizations.keyedById[data.id]
    return diffOnPatch(originalObject, data)
  }

  static instanceDefaults() {
    return {
      address: { stateId: 1 }
    }
  }
}

const servicePath = 'organizations'
const servicePlugin = makeServicePlugin({
  Model: Organization,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({
  before: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  after: {
    all: [],
    find: [],
    get: [],
    create: [],
    update: [],
    patch: [],
    remove: []
  },
  error: {}
})

export default servicePlugin