import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class Emailer extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'Emailer'
  static instanceDefaults() {
    return {}
  }
}
const servicePath = 'emailer'
const servicePlugin = makeServicePlugin({
  Model: Emailer,
  service: feathersClient.service(servicePath),
  servicePath
})

feathersClient.service(servicePath).hooks({})

export default servicePlugin