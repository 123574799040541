const chambers = [
  { text: 'President', value: 'president', fecKey: 'president' },
  { text: 'US Senate', value: 'us-senate', fecKey: 'senate' },
  { text: 'US House', value: 'us-house', fecKey: 'house' },
  { text: 'Governor', value: 'governor', fecKey: '' },
  { text: 'State Senate', value: 'state-senate', fecKey: '' },
  { text: 'State House', value: 'state-house', fecKey: '' }
]
const supportLevels = [
  { text: 'Support', value: 'support' },
  { text: 'Oppose', value: 'oppose' }
]
const parties = [
  { text: 'Republican', value: 'republican', fecKey: 'rep' },
  { text: 'Democrat', value: 'democrat', fecKey: 'dem' },
  { text: 'Independant', value: 'independant', fecKey: 'ind' },
  { text: 'Other', value: 'other' }
]

export {
  chambers as chambers,
  supportLevels as supportLevels,
  parties as parties
}