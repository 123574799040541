import feathersClient, { makeServicePlugin, BaseModel } from '../../api-client'

class ExpenseHistories extends BaseModel {
  constructor(data, options) {
    super(data, options)
  }
  static modelName = 'ExpenseHistories'
  static setupInstance (data, { models }) {
    if (data.user) {
      data.user = new models.api.User(data.user)
    }
    return data
  }
  get color() {
    switch (this.status) {
      case 'incomplete':
        return { text: 'grey', border: 'border-gray-700' };
      case 'submitted':
        return { text: 'primary', border: 'border-blue-800' };
      case 'rejected':
        return { text: 'red', border: 'border-red-600' };
      case 'approved':
        return { text: 'green', border: 'border-green-600' };
      case 'confirmed':
        return { text: 'blue', border: 'border-blue-600' };
      default: return '';
    }
  }
  get action() {
    switch (this.status) {
      case 'incomplete': return this.note ? 'Incompleted' : 'Created';
      case 'submitted': return 'Submitted';
      case 'rejected': return 'Rejected';
      case 'approved': return 'Approved';
      case 'confirmed': return 'Confirmed';
      default: return '';
    }
  }
  get icon() {
    switch (this.status) {
      case 'incomplete': return 'mdi-plus';
      case 'submitted': return 'mdi-file-document-arrow-right-outline';
      case 'rejected': return 'mdi-undo-variant';
      case 'approved': return 'mdi-check';
      case 'confirmed': return 'mdi-check-all';
      default: return '';
    }
  }
}
const servicePath = 'expense-histories'
const servicePlugin = makeServicePlugin({
  Model: ExpenseHistories,
  service: feathersClient.service(servicePath),
  servicePath
})

// Setup the client-side Feathers hooks.
feathersClient.service(servicePath).hooks({})

export default servicePlugin